body{
  color: #000;
  font-family: 'Nunito Semibold';
  text-align: center;
  background: white;
}

/* small grid */
#content
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 10px;
    max-width: 960px;
    margin: 0 auto;
    grid-template-areas:
        "header header header header"
        "sidenav sidenav sidenav sidenav"
        /*"section section section section"
        "section section section section"*/
        "main main main main"
        "main main main main"
        "footer footer footer footer";
}

/* desktop grid */
@media screen and (min-width: 760px)
{
  #content
  {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 10px;
    max-width: 960px;
    margin: 0 auto;
          grid-template-areas:
              "header header header header"
              /*"section section section section"
              "section section section section"*/
              "sidenav main main main"
              "sidenav main main main"
              "footer footer footer footer";
  }
}

header{
    grid-area: header;
}
main{
    grid-area: main;
}
aside{
    grid-area: aside;
}
sidenav{
    grid-area: sidenav;
}
section{
    grid-area: section;
    background: #3bbced;
}
footer{
    grid-area: footer;
    background: #cdecff;
}

header nav {
  border-bottom: 4px solid black;
  text-align: left;
  /*position: fixed;*/
  top: 0;
  width: 100%;
  background: #cdecff;
  z-index:1;
}

header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  padding-top: 5px;
}

header nav li {
  /*display: inline-block;*/
  padding-top: 16px;
  padding-bottom: 16px;
}

header nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  /*padding: 16px 32px;*/
}

header nav a:hover {
  /*background: black;*/
  color: white;
}

#headericon{
  /*display: inline-block;*/
  background: black;
  text-align: left;
}

sidenav nav {
  /*border-bottom: 4px solid black;*/
  text-align: left;
  /*position: fixed;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: #cdecff;*/
  z-index:1;
  /*padding-top: 20px;*/
}

sidenav nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #cdecff;
}

sidenav nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  display: block;
  padding: 16px 32px;
  border: 1px solid black;
}

sidenav nav a:hover {
  background: black;
  color: white;
}

.mainbody
{
  /*display: flex;
  flex-direction: column;
  justify-content: right;
  margin-left: auto;
  margin-right: auto;*/
 /* display: grid;
  grid-template-columns: repeat(4, 1fr);*/
  background: white;
  /*display: inline-block;*/
}

#wordtable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  /*width: 30%;*/
  width: 50%;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  /*margin-left: 5%;
  margin-right: 0%;*/
  margin-bottom: 10%;
  /*table-layout:fixed;*/
  text-align: justify;
  column-count: 1;
}

#wordtable td, #wordtable th {
  border: 1px solid #ddd;
  padding: 8px;
  /*width: 30%;*/
}

#wordtable tr:nth-child(even){background-color: #f2f2f2;}

#wordtable tr:hover {background-color: #ddd;}

#wordtable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  /*background-color: rgb(76, 119, 175);*/
  background-color: #cdecff;
  color: black;
}

@media screen and (max-width: 760px)
{
  sidenav nav a {
    padding: 8px 8px;
  }
}